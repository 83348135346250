import { Box, Card, Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';

import { styled } from '@mui/material/styles';
import Logo from 'src/components/Logo';
import LoginComponent from './Login';
import RegisterComponent from './Register';

const OverviewWrapper = styled(Box)(
  () => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
    align-items: center;
`
);

export function Register() {
  return (
    <OverviewWrapper>
      <Helmet>
        <title>Lager 2 | Registrierung</title>
      </Helmet>
      <Container maxWidth="md">
        <Box display="flex" justifyContent="center" py={2} alignItems="center">
          <Logo />
        </Box>
        <Card sx={{ p: 2, mb: 5, borderRadius: 4 }}>
          <RegisterComponent />
        </Card>
      </Container>
    </OverviewWrapper>
  );
}

export function Login() {
  return (
    <OverviewWrapper>
      <Helmet>
        <title>Lager 2 | Login</title>
      </Helmet>
      <Container maxWidth="sm">
        <Box display="flex" justifyContent="center" py={2} alignItems="center">
          <Logo />
        </Box>
        <Card sx={{ p: 2, mb: 5, borderRadius: 4 }}>
          <LoginComponent />
        </Card>
      </Container>
    </OverviewWrapper>
  );
}
