import { getBaseUrl } from 'src/config/apollo';
import { HttpResponse, post } from 'src/config/http';
import { LoginResult } from 'src/models/AuthResult';
import { RegisterInput } from 'src/utils/RegisterHelper';

export async function httpRegister(data: RegisterInput): Promise<LoginResult> {
  const formdata = new FormData();
  formdata.append('code', data.code);
  formdata.append('vorname', data.vorname);
  formdata.append('nachname', data.nachname);
  formdata.append('email', data.email);
  formdata.append('password', data.password);
  formdata.append('passwordConfirm', data.passwordConfirm);

  let response: HttpResponse<LoginResult>;

  try {
    response = await post<LoginResult>(`${getBaseUrl()}register`, formdata);
    const result = response.data;

    if (result) {
      return result;
    }
  } catch (e) {
    console.log(e);
    return Promise.reject(e);
  }
  return Promise.reject(new Error('failedRegistration'));
}
