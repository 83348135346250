import { object, string, TypeOf } from 'zod';

export const registerSchema = object({
  code: string()
    .nonempty('Registrierungs-Code ist ein Pflichtfeld')
    .max(100, 'Registrierungs-Code darf maximal 100 Zeichen lang sein'),
  vorname: string()
    .nonempty('Vorname ist ein Pflichtfeld')
    .max(100, 'Vorname darf maximal 100 Zeichen lang sein'),
  nachname: string()
    .nonempty('Nachname ist ein Pflichtfeld')
    .max(100, 'Nachname darf maximal 100 Zeichen lang sein'),
  email: string()
    .nonempty('Email ist ein Pflichtfeld')
    .email('Email-Adresse ungültig'),
  password: string()
    .nonempty('Passwort is ein Pflichtfeld')
    .min(6, 'Paswort muss mindestens 6 Zeichen lang sein')
    .max(32, 'Paswort darf maximal 32 Zeichen lang sein'),
  passwordConfirm: string().nonempty('Bitte Passwort bestätigen')
}).refine((data) => data.password === data.passwordConfirm, {
  path: ['passwordConfirm'],
  message: 'Die Passwörter sind nicht identisch'
});

export type RegisterInput = TypeOf<typeof registerSchema>;
