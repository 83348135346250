/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, Suspense } from 'react';
import { RouteObject, To } from 'react-router';
import { Navigate } from 'react-router-dom';

import BaseLayout from './layouts/BaseLayout';

import AuthHelper from './auth/AuthHelper';
import SuspenseLoader from './components/SuspenseLoader';
import SidebarLayout from './layouts/SidebarLayout';
import VendingLayout from './layouts/VendingLayout';
import lazyWithRetry from './utils/LazyRetry';

type AllowedType = 'user' | 'admin' | 'dev';

// eslint-disable-next-line react/function-component-definition
const Loader = (Component: any) => (props: any) =>
  (
    // eslint-disable-next-line react/jsx-no-comment-textnodes
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const Dashboard = Loader(lazyWithRetry(() => import('./pages/Dashboard')));

const BeerList = Loader(lazyWithRetry(() => import('./pages/BeerList')));

const Chat = Loader(lazyWithRetry(() => import('./pages/Chat')));

const Transactions = Loader(
  lazyWithRetry(() => import('./pages/Transactions'))
);

const TextToSpeech = Loader(
  lazyWithRetry(() => import('./pages/TextToSpeech'))
);

const Stats = Loader(lazyWithRetry(() => import('./pages/Stats')));

const Freunde = Loader(lazyWithRetry(() => import('./pages/Freunde')));

// User
const UserProfile = Loader(lazyWithRetry(() => import('./pages/UserProfile')));

// Management
const UserManagement = Loader(
  lazyWithRetry(() => import('./pages/Management/UserManagement'))
);

const KontoManagement = Loader(
  lazyWithRetry(() => import('./pages/Management/KontoManagement'))
);

const LagerManagement = Loader(
  lazyWithRetry(() => import('./pages/Management/LagerManagement'))
);

const AutomatenManagement = Loader(
  lazyWithRetry(() => import('./pages/Management/AutomatenManagement'))
);

//Dev
const AutomatenLogs = Loader(
  lazyWithRetry(() => import('./pages/Dev/AutomatenLogs'))
);

// Status
const Status404 = Loader(
  lazyWithRetry(() => import('./pages/Status/Status404'))
);
const Status403 = Loader(
  lazyWithRetry(() => import('./pages/Status/Status403'))
);
const Status500 = Loader(
  lazyWithRetry(() => import('./pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazyWithRetry(() => import('./pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazyWithRetry(() => import('./pages/Status/Maintenance'))
);

const Vending = Loader(lazyWithRetry(() => import('./pages/Vending')));
const VendingChip = Loader(lazyWithRetry(() => import('./pages/Vending/Chip')));
const VendingVend = Loader(lazyWithRetry(() => import('./pages/Vending/Vend')));
const VendingRegister = Loader(
  lazyWithRetry(() => import('./pages/Vending/Register'))
);

const ProtectedRoute: React.FC<{
  allowed: AllowedType;
  redirectPath: To;
  children: ReactElement<any, any>;
}> = ({ allowed, redirectPath = '/landing', children }) => {
  if (allowed == 'user' && !AuthHelper.getLoginData()) {
    return <Navigate to={redirectPath} replace />;
  } else if (allowed == 'admin' && !AuthHelper.isAdmin()) {
    return <Navigate to={redirectPath} replace />;
  } else if (allowed == 'dev' && !AuthHelper.isDeveloper()) {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
};

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboard" replace />
      },
      {
        path: 'login',
        element: <Navigate to="/dashboard" replace />
      },
      {
        path: 'register',
        element: <Navigate to="/dashboard" replace />
      },

      {
        path: 'chat',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <Chat />
          }
        ]
      },
      {
        path: 'dashboard',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <Dashboard />
          }
        ]
      },
      {
        path: 'notifications',
        element: <Navigate to="/dashboard" replace />
        //TODO ADD PAGE
      },
      {
        path: 'beerlist',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <BeerList />
          }
        ]
      },
      {
        path: 'transactions',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <Transactions />
          }
        ]
      },
      {
        path: 'stats',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <Stats />
          }
        ]
      },
      {
        path: 'freunde',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <Freunde />
          }
        ]
      },
      {
        path: 'profile',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <UserProfile />
          }
        ]
      },
      {
        path: 'tts',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: (
              <ProtectedRoute allowed={'admin'} redirectPath="/status/403">
                <TextToSpeech />
              </ProtectedRoute>
            )
          }
        ]
      },
      {
        path: 'verwaltung',
        element: <SidebarLayout />,
        children: [
          {
            path: 'benutzer',
            element: (
              <ProtectedRoute allowed={'admin'} redirectPath="/status/403">
                <UserManagement />
              </ProtectedRoute>
            )
          },
          {
            path: 'konten',
            element: (
              <ProtectedRoute allowed={'admin'} redirectPath="/status/403">
                <KontoManagement />
              </ProtectedRoute>
            )
          },
          {
            path: 'lager',
            element: (
              <ProtectedRoute allowed={'admin'} redirectPath="/status/403">
                <LagerManagement />
              </ProtectedRoute>
            )
          },
          {
            path: 'automaten',
            element: (
              <ProtectedRoute allowed={'admin'} redirectPath="/status/403">
                <AutomatenManagement />
              </ProtectedRoute>
            )
          }
        ]
      },
      {
        path: 'dev',
        element: <SidebarLayout />,
        children: [
          {
            path: 'automatenLogs',
            element: (
              <ProtectedRoute allowed={'dev'} redirectPath="/status/403">
                <AutomatenLogs />
              </ProtectedRoute>
            )
          }
        ]
      },
      {
        path: 'vending',
        element: <VendingLayout />,
        children: [
          {
            path: '',
            element: <Vending />
          },
          {
            path: 'chip',
            element: <VendingChip />
          },
          {
            path: 'vend',
            element: <VendingVend />
          },
          {
            path: 'register',
            element: <VendingRegister />
          }
        ]
      },
      {
        path: 'status',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '403',
            element: <Status403 />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="tasks" replace />
      },
      {
        path: 'tasks',
        element: <Dashboard />
      },
      {
        path: 'messenger',
        element: <Chat />
      }
    ]
  }
];

export default routes;
