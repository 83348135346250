import { Refresh } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import HeaderNotifications from './Notifications';

function HeaderButtons() {
  const reload = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    }

    window.location.reload();
  };

  return (
    <Box sx={{ mr: 1 }}>
      {/*<HeaderSearch />*/}
      <Box sx={{ mx: 0.5 }} component="span">
        <IconButton onClick={reload}>
          <Refresh color="primary" />
        </IconButton>
        {<HeaderNotifications />}
      </Box>
    </Box>
  );
}

export default HeaderButtons;
