import { Speaker } from '@mui/icons-material';
import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import TTSMessage from 'src/models/TTSMessage';

import TTSWebSocket from 'src/websocket/TTSWebSocket';

type Colors =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning';

const TTSPlayer = () => {
  const [speakActive, setSpeakActive] = React.useState(false);
  const [color, setColor] = React.useState<Colors>('primary');
  const ttsSocket: TTSWebSocket = TTSWebSocket.getInstance();
  let retryTimer: NodeJS.Timeout;
  let retryCounter = 0;
  const maxRetries = 3;

  useEffect(() => {
    ttsSocket.addListener('tts', (data) => playTextToSpeech(data));

    return () => {
      ttsSocket.removeListener('tts');
    };
  }, []);

  const playTextToSpeech = (data: string) => {
    setSpeakActive(true);
    setColor('warning');
    let msg: TTSMessage = JSON.parse(data);
    speak(msg);
    retryCounter = 0;
  };

  const speak = (msg: TTSMessage) => {
    responsiveVoice.speak(msg.text, msg.voice, {
      rate: msg.rate,
      pitch: msg.pitch,
      volume: msg.volume,
      onstart: () => {
        console.log('Started speaking : ' + msg.text);
        setSpeakActive(true);
        setColor('success');
        checkRetry(msg);
      },
      onend: () => {
        speakEnded();
      },
      onerror: () => {
        speakError('Text to Speech konnte nicht abgespielt werden.');
      }
    });
  };

  const checkRetry = (msg: TTSMessage) => {
    if (retryCounter < maxRetries) {
      retryCounter++;
      if (retryTimer) clearTimeout(retryTimer);
      retryTimer = setTimeout(() => {
        setColor('info');
        speak(msg);
      }, 20000);
    } else {
      speakError('Text to Speech konnte nicht abgespielt werden. API-Fehler!');
    }
  };

  const speakEnded = () => {
    console.log('Finished speaking');
    setColor('primary');
    setSpeakActive(false);
    if (retryTimer) clearTimeout(retryTimer);
  };

  const speakError = (msg: string) => {
    toast.error(msg);
    setColor('error');
    setSpeakActive(false);
  };

  return (
    <Button
      size="large"
      onClick={() => location.reload()}
      disabled={!speakActive}
      color={color}
    >
      <Speaker />
    </Button>
  );
};

export default TTSPlayer;
