import { zodResolver } from '@hookform/resolvers/zod';
import { Key, Lock, Mail } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Box,
  Container,
  FormGroup,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { httpRegister } from 'src/auth/register';
import { RegisterResult } from 'src/models/RegisterResult';
import { RegisterInput, registerSchema } from 'src/utils/RegisterHelper';

function RegisterComponent() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const {
    register,
    formState: { errors, isSubmitSuccessful },
    reset,
    handleSubmit
  } = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema)
  });

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      console.log('Submit successful');
    }
  }, [isSubmitSuccessful]);

  const sendRegister = async (data: RegisterInput) => {
    setLoading(true);
    const resultA = httpRegister(data)
      .then((result: RegisterResult) => {
        if (result.success) {
          return 'ok';
        }
        return result.message;
      })
      .catch((e: Error) => {
        console.log('Register Error', e);
      });

    const res = await resultA;
    if (res === 'ok') {
      reset();
      navigate('/login');
      setLoading(false);
    } else {
      setError(res);
      setLoading(false);
    }
  };

  const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
    setLoading(true);
    sendRegister(values);
    console.log('Values', values);
  };
  console.log(errors);

  return (
    <Container maxWidth="lg" disableGutters={true}>
      <Typography sx={{ mb: 2, textAlign: 'center' }} variant="h1">
        Registrierung
      </Typography>
      <Typography sx={{ mb: 2, textAlign: 'center' }}>
        <strong>
          Wenn du bereits einen Account hast, klicke <a href="./login">HIER</a>{' '}
          um dich zu <a href="./login">einzuloggen</a>.
        </strong>
      </Typography>
      {error && (
        <Alert severity="error" style={{ color: '#FFF' }}>
          <AlertTitle>Registrierung fehlgeschlagen</AlertTitle>
          {error}
        </Alert>
      )}
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <FormGroup sx={{ marginTop: 2 }}>
          <TextField
            label="Registrierungscode -> Findest du im Lager 2"
            type="text"
            placeholder="Registrierungscode eingeben"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Key />
                </InputAdornment>
              )
            }}
            error={!!errors.code}
            helperText={
              errors.code ? (
                <span>{errors.code.message?.toString()}</span>
              ) : null
            }
            {...register('code')}
          />
        </FormGroup>
        <h3 style={{ textAlign: 'center' }}>Zugangsdaten</h3>
        <FormGroup sx={{ marginTop: 2 }}>
          <TextField
            label="E-Mail"
            required
            placeholder="E-Mail-Adresse eingeben"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Mail />
                </InputAdornment>
              )
            }}
            error={!!errors.email}
            helperText={
              errors.email ? (
                <span>{errors.email.message?.toString()}</span>
              ) : null
            }
            {...register('email')}
          />
        </FormGroup>
        <Grid container justifyContent="center" spacing={1}>
          <Grid item xs={12} sm={6}>
            <FormGroup sx={{ marginTop: 2 }}>
              <TextField
                label="Passwort"
                type="password"
                required
                placeholder="Passwort eingeben"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  )
                }}
                error={!!errors.password}
                helperText={
                  errors.password ? (
                    <span>{errors.password.message?.toString()}</span>
                  ) : null
                }
                {...register('password')}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormGroup sx={{ marginTop: 2 }}>
              <TextField
                label="Passwort-Wiederholung"
                type="password"
                placeholder="Passwort Wiederholung"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  )
                }}
                error={!!errors.passwordConfirm}
                helperText={
                  errors.passwordConfirm ? (
                    <span>{errors.passwordConfirm.message?.toString()}</span>
                  ) : null
                }
                {...register('passwordConfirm')}
              />
            </FormGroup>
          </Grid>
        </Grid>
        <h3 style={{ textAlign: 'center' }}>Name</h3>
        <Grid container justifyContent="center" spacing={1}>
          <Grid item xs={12} sm={6}>
            <FormGroup>
              <TextField
                label="Vorname"
                type="text"
                placeholder="Vorname"
                error={!!errors.code}
                helperText={
                  errors.vorname ? (
                    <span>{errors.vorname.message?.toString()}</span>
                  ) : null
                }
                {...register('vorname')}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormGroup>
              <TextField
                label="Nachname"
                type="text"
                placeholder="Nachname"
                error={!!errors.code}
                helperText={
                  errors.nachname ? (
                    <span>{errors.nachname.message?.toString()}</span>
                  ) : null
                }
                {...register('nachname')}
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <LoadingButton
            disabled={loading}
            variant="contained"
            color="primary"
            type="submit"
            sx={{ marginTop: 2 }}
            loading={loading}
          >
            registrieren
          </LoadingButton>
        </Grid>
      </Box>
    </Container>
  );
}

export default RegisterComponent;
