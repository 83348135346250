import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import {
  Alert,
  alpha,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  Popover,
  Tooltip,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';

import { getNotificationToken, isMessagingSupported } from 'src/utils/Firebase';

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

function HeaderNotifications() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isTokenFound, setTokenFound] = useState(false);
  const [isTokenUpdated, setTokenUpdated] = useState(false);
  const [isSupported, setSupported] = useState(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  useEffect(() => {
    if (isMessagingSupported()) {
      setSupported(true);
      getNotificationToken(setTokenFound, setTokenUpdated);
    } else {
      setSupported(false);
    }
  }, []);

  return (
    <>
      <Tooltip arrow title="Benachrichtigungen">
        <IconButton
          color={isTokenFound ? 'primary' : 'error'}
          ref={ref}
          onClick={handleOpen}
        >
          {/*<NotificationsBadge
            badgeContent={1}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >*/}
          <NotificationsActiveTwoToneIcon />
          {/*
          </NotificationsBadge>
          */}
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box
          sx={{ p: 2 }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">Benachrichtigungen</Typography>
        </Box>
        {isSupported ? (
          !isTokenFound && (
            <Alert severity="error">
              Benachrichtigungen sind deaktiviert.
              <br />
              Bitte in den Browser-Einstellungen aktivieren.
            </Alert>
          )
        ) : (
          <Alert severity="warning">
            Benachrichtigungen werden von diesem Browser nicht unterstützt.
          </Alert>
        )}
        <Divider />
        <List sx={{ p: 0 }}>
          {/*<ListItem
            sx={{ p: 2, minWidth: 350, display: { xs: 'block', sm: 'flex' } }}
          >
            <Box flex="1">
              <Box display="flex" justifyContent="space-between">
                <Typography sx={{ fontWeight: 'bold' }}>
                  Messaging Platform
                </Typography>
                <Typography variant="caption" sx={{ textTransform: 'none' }}>
                  {formatDistance(subDays(new Date(), 3), new Date(), {
                    addSuffix: true
                  })}
                </Typography>
              </Box>
              <Typography
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {' '}
                new messages in your inbox
              </Typography>
            </Box>
          </ListItem>
          */}
        </List>
      </Popover>
    </>
  );
}

export default HeaderNotifications;
