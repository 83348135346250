import { useRoutes } from 'react-router-dom';

import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import de from 'date-fns/locale/de';
import AuthHelper from './auth/AuthHelper';
import loginRegisterRoutes from './routerLoginRegister';
import protectedRoutes from './routerProtected';
import ThemeProvider from './theme/ThemeProvider';

function App() {
  const contentProtected = useRoutes(protectedRoutes);
  const contentLoginRegister = useRoutes(loginRegisterRoutes);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
        <CssBaseline />
        {AuthHelper.getLoginData() && AuthHelper.getLoginData()!.success
          ? contentProtected
          : contentLoginRegister}
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
