import WebSocketHandler from './WebSocketHandler';

export default class TTSWebSocket extends WebSocketHandler {
  private static instance: TTSWebSocket = new TTSWebSocket();

  constructor() {
    super();
    super.url = this.getUrl();
  }

  public static getInstance(): TTSWebSocket {
    if (TTSWebSocket.instance == null) {
      TTSWebSocket.instance = new TTSWebSocket();
    }
    return TTSWebSocket.instance;
  }

  private getUrl(): string {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      return 'ws://localhost:7000/ws/tts';
    }
    return 'wss://backend.l2.taucher.tirol/ws/tts';
  }
}
