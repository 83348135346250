/* eslint-disable react/jsx-props-no-spreading */
import { Suspense } from 'react';
import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';

import BaseLayout from './layouts/BaseLayout';

import SuspenseLoader from './components/SuspenseLoader';
import VendingLayout from './layouts/VendingLayout';
import { Login, Register } from './pages/login-register';
import lazyWithRetry from './utils/LazyRetry';

// eslint-disable-next-line react/function-component-definition
const Loader = (Component: any) => (props: any) =>
  (
    // eslint-disable-next-line react/jsx-no-comment-textnodes
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Status

const Status404 = Loader(
  lazyWithRetry(() => import('./pages/Status/Status404'))
);
const Status500 = Loader(
  lazyWithRetry(() => import('./pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazyWithRetry(() => import('./pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazyWithRetry(() => import('./pages/Status/Maintenance'))
);

const Vending = Loader(lazyWithRetry(() => import('./pages/Vending')));

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/login" replace />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'register',
        element: <Register />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: 'vending',
        element: <VendingLayout />,
        children: [
          {
            path: '',
            element: <Vending />
          },
          {
            path: '*',
            element: <Navigate to="/vending" replace />
          }
        ]
      },
      {
        path: '*',
        element: <Navigate to="login" replace />
      }
    ]
  }
];

export default routes;
