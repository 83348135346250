import { Avatar, AvatarProps, Tooltip } from '@mui/material';
import React from 'react';

type BenutzerType = {
  vorname?: string;
  nachname?: string;
  bild?: string;
};

const BenutzerAvatar: React.FC<{
  benutzer: BenutzerType;
  avatarProps?: AvatarProps;
}> = ({ benutzer, avatarProps }) => {
  const [initalien, setInitalien] = React.useState<string>('');

  React.useEffect(() => {
    setInitalien(`${benutzer.vorname.charAt(0)}${benutzer.nachname.charAt(0)}`);
  }, [benutzer]);

  if (benutzer.bild) {
    return (
      <Tooltip title={benutzer.vorname + ' ' + benutzer.nachname}>
        <Avatar {...avatarProps} src={benutzer.bild} />
      </Tooltip>
    );
  }
  return (
    <Tooltip title={benutzer.vorname + ' ' + benutzer.nachname}>
      <Avatar {...avatarProps}>{initalien}</Avatar>
    </Tooltip>
  );
};

export default BenutzerAvatar;
