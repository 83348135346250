// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage
} from 'firebase/messaging';
import { apolloClient } from 'src/config/apollo';
import { UpdateFcmTokenDocument } from 'src/generated/graphql';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyC7Usa94zi0b9xsHfzBbMQO9HmLWiVez2w',
  authDomain: 'lager02-wp.firebaseapp.com',
  projectId: 'lager02-wp',
  storageBucket: 'lager02-wp.appspot.com',
  messagingSenderId: '938298498042',
  appId: '1:938298498042:web:bb278e1509044a5118a243',
  measurementId: 'G-Z2RFMQNQ9B'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const messaging = getMessaging(app);

export const isMessagingSupported = () => {
  return isSupported();
};

export const getNotificationToken = (setTokenFound, setTokenUpdated) => {
  return getToken(messaging, {
    vapidKey:
      'BDsI82a5H-0efIhNiPvOA7lMkOScyRo4FCIJJ9_tR3neWzlCrOYkx7VbxTQ2hyaQz7sVnA2nJfPUHo-VqsExCCo'
  })
    .then((currentToken) => {
      if (currentToken) {
        //console.log('current token for client: ', currentToken);
        setTokenFound(true);
        apolloClient
          .mutate({
            mutation: UpdateFcmTokenDocument,
            variables: { fcmToken: currentToken }
          })
          .then((res) => {
            setTokenUpdated(res.data.updateFcmToken.success);
          })
          .catch((err) => {
            console.log(err);
            setTokenUpdated(false);
          });

        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          'No registration token available. Request permission to generate one.'
        );
        setTokenFound(false);
        setTokenUpdated(false);
        // shows on the UI that permission is required
        Notification.requestPermission().then((permission) => {
          console.log('Access for notification is ', permission);
        });
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
      console.log('FIREBASE MSG:' + payload);
    });
  });
