import { getBaseUrl } from 'src/config/apollo';
import { HttpResponse, post } from 'src/config/http';
import { LoginResult } from '../models/AuthResult';
import AuthHelper from './AuthHelper';

export async function httpLogin(
  email: string,
  password: string
): Promise<LoginResult> {
  const formdata = new FormData();
  formdata.append('email', email);
  formdata.append('pwd', password);

  let response: HttpResponse<LoginResult>;

  try {
    const loginUrl = `${getBaseUrl()}login`;
    response = await post<LoginResult>(loginUrl, formdata);
    const result = response.data;

    if (result && result.success) {
      AuthHelper.setLoginData(result);
      return result;
    }
    localStorage.removeItem('token');
  } catch (e) {
    console.log(e);
    return Promise.reject(e);
  }
  return Promise.reject(new Error('wrongCredentials'));
}
