import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  FormGroup,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginResultType } from 'src/auth/AuthHelper';
import { httpLogin } from 'src/auth/login';
import { LoginResult } from 'src/models/AuthResult';

function LoginComponent() {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [loginResult, setLoginResult] = React.useState<LoginResultType | null>(
    null
  );
  const [loading, setLoading] = React.useState(false);

  const login = async () => {
    setLoading(true);
    const resultA = httpLogin(email, password)
      .then((result: LoginResult) => {
        if (result.benutzer) {
          return 'ok';
        }
        return 'wrongCredentials';
      })
      .catch((e: Error) => {
        setLoading(false);
        if (e.message === 'wrongCredentials') {
          return 'wrongCredentials';
        }
        return 'error';
      });

    const res = await resultA;
    setLoginResult(res);
    if (res === 'ok') {
      setLoading(false);
      navigate('/dashboard');
    }
  };

  const keyDownHandler = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      login();
    }
  };

  React.useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  });

  return (
    <Container maxWidth="lg" disableGutters={true} sx={{ textAlign: 'center' }}>
      <Typography sx={{ mb: 2 }} variant="h1">
        Login
      </Typography>
      <strong>
        Wenn du noch keinen Account hast, klicke <a href="./register">HIER</a>{' '}
        um dich zu <a href="./register">registrieren</a>.
      </strong>
      <br />
      <br />
      Bitte logge dich mit ein:
      <br />
      <FormGroup sx={{ marginTop: 2 }}>
        <TextField
          label="E-Mail"
          value={email}
          type="text"
          placeholder="E-Mail-Adresse eingeben"
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value);
          }}
        />
      </FormGroup>
      <FormGroup sx={{ marginTop: 2 }}>
        <TextField
          label="Passwort"
          value={password}
          type="password"
          placeholder="Passwort eingeben"
          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(e.target.value);
          }}
        />
      </FormGroup>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={8}
          justifyContent="space-between"
          alignItems="center"
          pt={1}
        >
          {loginResult != null && loginResult !== 'ok' && (
            <Alert severity="error" sx={{ width: '100%', color: '#FFF' }}>
              {loginResult === 'wrongCredentials' ? (
                <span>Falsche E-Mail oder Passwort!</span>
              ) : (
                <span>Keine Verbindung zum Server!</span>
              )}
            </Alert>
          )}
        </Grid>
        <Grid item xs={12} sm={4} justifyContent="flex-end">
          <Box display="flex" justifyContent="flex-end">
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => {
                login();
              }}
              sx={{ marginTop: 2 }}
              startIcon={
                loading ? (
                  <CircularProgress size="1em" color="secondary" />
                ) : null
              }
            >
              Login
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default LoginComponent;
