import { ApolloProvider } from '@apollo/client';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import 'nprogress/nprogress.css';
import { ToastContainer } from 'react-toastify';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { apolloClient } from './config/apollo';

import 'react-toastify/dist/ReactToastify.css';

Sentry.init({
  dsn: 'https://51bb9ecb29a146e4be5401792d4712d0@o4505316658184192.ingest.sentry.io/4505316664999936',
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        'https://backend.l2.taucher.tirol',
        'https://l2.taucher.tirol'
      ]
    }),
    new Sentry.Replay()
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <HelmetProvider>
      <SidebarProvider>
        <BrowserRouter>
          <App />
          <ToastContainer theme="dark" position="bottom-center" />
        </BrowserRouter>
      </SidebarProvider>
    </HelmetProvider>
  </ApolloProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
