import PropTypes from 'prop-types';
import { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import { Box, Container } from '@mui/material';
import VendingTopBar from 'src/pages/Vending/TopBar';

interface VendingLayoutProps {
  children?: ReactNode;
}

const VendingLayout: FC<VendingLayoutProps> = ({ children }) => {
  return (
    <>
      <VendingTopBar />
      <Box
        mt={3}
        display="flex"
        alignContent={'center'}
        justifyContent="middle"
        minHeight="80vh"
      >
        <Container maxWidth="lg" sx={{ textAlign: 'center' }}>
          {children || <Outlet />}
        </Container>
      </Box>
    </>
  );
};

VendingLayout.propTypes = {
  children: PropTypes.node
};

export default VendingLayout;
