import { Refresh } from '@mui/icons-material';
import { Box, Button, Grid } from '@mui/material';
import TTSPlayer from './Services/TTSPlayer';

const VendingTopBar = () => {
  return (
    <Box
      sx={{
        backgroundColor: 'rgba(0,0,0,0.3)',
        margin: '0 auto',
        borderRadius: '0 0 10px 10px'
      }}
      width={'150px'}
    >
      <Grid container justifyContent={'space-between'}>
        <Grid item xs={6}>
          <TTSPlayer />
        </Grid>
        <Grid item xs={6} textAlign={'right'}>
          <Button size="large" onClick={() => location.reload()}>
            <Refresh />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VendingTopBar;
