import { Box, styled, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
  return (
    <LogoWrapper to="/">
      <img src="logo.svg" width="40%" alt="Logo" style={{ margin: '0 auto' }} />
    </LogoWrapper>
  );
}

export default Logo;
